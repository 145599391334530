<script>
export default {
  name: "ElementCard",
  props: {
    dataArray: { type: Array },
  },
  data() {
    return {
      showModal: false,
      modalData: Object,
      modalPreviewSrc: "No image found",
    };
  },
  methods: {
    popModal(dataObject) {
      this.modalData = dataObject;
      this.showModal = true;
      this.modalPreviewSrc = require(`../data/Previews${dataObject.image}`);
    },
  },
};
</script>

<template>
  <div id="cardContainer">
    <div v-if="showModal" id="modal" @click="showModal = !showModal">
      <img id="modalPreview" :src="modalPreviewSrc" />
      <ul id="modalInfos">
        <h3>{{ modalData.name }}</h3>
        <li>
          <h4>Release :</h4>
          {{ modalData.release }}
          <span>(YYYY / MM / DD)</span>
        </li>
        <li>
          <h4>Last update :</h4>
          {{ modalData.update }}
          <span>(YYYY / MM / DD)</span>
        </li>
        <li>
          <h4>Type :</h4>
          <ul class="subUl">
            <li v-for="t in modalData.type" :key="t">{{ t }}</li>
          </ul>
        </li>
        <li id="available">
          <h4>Available :</h4>
          <ul>
            <li v-if="modalData.available.midlander.genre.male || modalData.available.midlander.genre.female">
              {{ modalData.available.midlander.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.midlander.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.midlander.genre.female" />
            </li>
            <li v-if="modalData.available.highlander.genre.male || modalData.available.highlander.genre.female">
              {{ modalData.available.highlander.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.highlander.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.highlander.genre.female" />
            </li>
            <li v-if="modalData.available.roegadyn.genre.male || modalData.available.roegadyn.genre.female" >
              {{ modalData.available.roegadyn.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.roegadyn.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.roegadyn.genre.female" />
            </li>
            <li v-if="modalData.available.elezen.genre.male || modalData.available.elezen.genre.female" >
              {{ modalData.available.elezen.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.elezen.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.elezen.genre.female" />
            </li>
            <li v-if="modalData.available.miqote.genre.male || modalData.available.miqote.genre.female" >
              {{ modalData.available.miqote.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.miqote.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.miqote.genre.female" />
            </li>
            <li v-if="modalData.available.lalafell.genre.male || modalData.available.lalafell.genre.female" >
              {{ modalData.available.lalafell.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.lalafell.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.lalafell.genre.female" />
            </li>
            <li v-if="modalData.available.aura.genre.male || modalData.available.aura.genre.female" >
              {{ modalData.available.aura.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.aura.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.aura.genre.female" />
            </li>
            <li v-if="modalData.available.viera.genre.male || modalData.available.viera.genre.female" >
              {{ modalData.available.viera.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.viera.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.viera.genre.female" />
            </li>
            <li v-if="modalData.available.hrothgar.genre.male || modalData.available.hrothgar.genre.female" >
              {{ modalData.available.hrothgar.name }}
              <img src="../assets/man.png" alt="man icon" v-if="modalData.available.hrothgar.genre.male" />
              <img src="../assets/woman.png" alt="woman icon" v-if="modalData.available.hrothgar.genre.female" />
            </li>
          </ul>
        </li>
        <li id="liLink">
          <a :href="modalData.link" target="_blank">
            <img src="../assets/right-arrow.png" alt="Download arrow left" />
            Download Link
            <img src="../assets/left-arrow.png" alt="Download arrow right" />
          </a>
        </li>
      </ul>
    </div>
    <article
      v-for="data in dataArray"
      :class="`elementCard ${data.filter}`"
      :key="data"
      @click="popModal(data)"
    >
      <h4>{{ data.name }}</h4>
      <p>
        <img
          class="elementPreview"
          :src="require(`../data/Previews${data.image}`)"
        />
        (Click to show infos)
      </p>
    </article>
  </div>
</template>

<style>
#cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1em 0.5em;
  align-items: top;
  padding: 3vh;
  max-width: 100%;
  overflow: auto;
}
.elementCard {
  min-width: 150px;
  width: 15em;
  padding: 0.5em;
}
.elementCard:hover {
  background-color: rgb(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 1em;
  border: solid 1px cornflowerblue;
  box-shadow: 1px 1px 2px 0px darkmagenta;
}
.elementCard h4 {
  display: inline;
  border-left: solid 4px;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  padding: 0.15em 0.5em;
}
.elementPreview {
  width: 100%;
  border-radius: 0.5em;
  margin-top: 1em;
}
.elementCard p {
  font-size: 0.75em;
}
#modal {
  position: absolute;
  top: 0.25vh;
  left: 0.25vw;
  z-index: 99;
  width: 99.5%;
  height: 99.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.25em);
  background-color: rgb(0, 0, 0, 0.5);
}
#modalPreview {
  width: 50%;
  max-width: 1024px;
  border-radius: 1em;
  box-shadow: 0px 0px 5px lightgoldenrodyellow;
}
#modalInfos {
  width: 30%;
  max-width: 400px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-left: 1em;
  background-color: rgba(72, 88, 116, 0.75);
  border-radius: 0.5em;
  padding: 1em;
  box-shadow: 0px 0px 5px #fafad2;
}
#modalInfos h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
  padding: 0.15em 0.5em;
  grid-area: 1 / 1 / 1 / 3;
  border-left: solid 4px;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}
#modalInfos h4 {
  text-decoration: underline;
}
#modalInfos li {
  list-style: none;
  display: block;
  padding: 0.25em 0;
}
#modalInfos span {
  display: block;
  font-size: 0.5em;
}
#modalInfos ul img {
  display: inline;
  width: 2em;
}
#liLink {
  grid-area: 5 / 1 / 5 / 3;
}
#liLink a {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5em;
}
#liLink img {
  width: 2em;
}
#available {
  grid-area: 4 / 1 / 4 / 3;
  box-shadow: none;
  background-color: transparent;
  margin-left: 0;
  padding: 0;
}
#available ul {
  display: flex;
  flex-wrap: wrap;
}
#available li {
  margin-left: 0em;
  width: 50%;
}
#available li img {
  margin-left: 0.25em;
  width: 1em;
}
</style>
