function lastUpdates(datas,filter) {
    let howMany = 3
    let myDatas = []
    datas.forEach((e) => {
        e.forEach((i) => {
            myDatas.push(i)
        })
    })
    myDatas = sortedByOption(myDatas,"Update",filter)
    return myDatas.slice(0,howMany)
}
function sortedByOption(datas,option, filter){
    switch(option){
        case "Name" : 
            datas.sort((a,b)=> {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                let comparison = 0;
                if (nameA > nameB) {
                    comparison = 1;
                } else if (nameA < nameB) {
                    comparison = -1;
                }
                return comparison})
            break
        case "Release" : 
            datas.sort((a, b) => {
                const releaseA = a.release;
                const releaseB = b.release;
                let comparison = 0;
                if (releaseA < releaseB) {
                    comparison = 1;
                } else if (releaseA > releaseB) {
                    comparison = -1;
                }
                return comparison})
            break
        case "Update" :
            datas.sort((a, b) => {
                const updateA = a.update;
                const updateB = b.update;
                let comparison = 0;
                if (updateA < updateB) {
                    comparison = 1;
                } else if (updateA > updateB) {
                    comparison = -1;
                }
                return comparison})
        return filtered(datas,filter) 
    }
}
function filtered(datas,filter){
    let filters=[]
    if (filter=="Passion"){
        filters = ["Pure","Soft","Passion"]
    }else if (filter=="Soft"){
        filters = ["Pure","Soft"]
    }else{
        filters = ["Pure"]
    }
    let filteredData = []

    datas.forEach((d)=>{
        filters.forEach((f)=>{
            if(d.filter==f){filteredData.push(d)}
        })
    })
    datas = filteredData
    return datas
}

export {lastUpdates, sortedByOption, filtered}